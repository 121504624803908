import { Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/react";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";

interface ILawResponse {
    status: number
    message: string
    lawData: Array<ILaw>
}

interface ILaw {
    id: number
    title: string
    text: string
    date: Date
}

interface IVoteResponse {
    status: number
    message: string
    voteData: IVote
}

interface IVote {
    lawId: number
    votingResult: {
        positive: number
        negative: number
    }
}

interface IPartyVoteResponse {
    status: number
    message: string
    partyVoteData: Array<IPartyVote>
}

interface IPartyVote {
    id: number
    positive: number
    negative: number
    neutral: number
    name: string
    lawId: number
}

interface IPartyResponse {
    status: number
    message: string
    partyData: Array<IParty>
}

interface IParty {
    id: number
    name: string
    showLocation: string
}

export function Archive() {
    const [stateLaws, setStateLaws] = useState<Array<ILaw>>([]);
    const [stateParty, setStateParty] = useState<Array<IParty>>([]);
    const [stateDotMatrix, setStateDotMatrix] = useState<JSX.Element>();
    const [stateBarChart, setStateBarChart] = useState<JSX.Element>();
    const [stateLawIndex, setStateLawIndex] = useState(0);
    const [stateVisibilityPreviousLaw, setStateVisibilityPreviousLaw] = useState<string>("hidden");
    const [stateVisibilityNextLaw, setStateVisibilityNextLaw] = useState<string>("hidden");

    useEffect(() => {
        getLaws().then(laws => {
            if (JSON.stringify(laws.sort()) !== JSON.stringify(stateLaws.sort())) {
                setStateLaws(laws)
            }
        });

        getParties().then(party => {
            if (JSON.stringify(party.sort()) !== JSON.stringify(stateParty.sort())) {
                setStateParty(party)
            }
        });
    }, []);

    useEffect(() => {
        if (stateLawIndex > 0) {
            setStateVisibilityPreviousLaw("visible");
        } else {
            setStateVisibilityPreviousLaw("hidden");
        }

        if (stateLawIndex < stateLaws.length - 1) {
            setStateVisibilityNextLaw("visible");
        } else {
            setStateVisibilityNextLaw("hidden");
        }

        let law = stateLaws.at(stateLawIndex)

        if (law !== undefined) {
            getPartyVote(law.id).then(partyVote => {
                setStateBarChart(getBarChart(partyVote, stateParty));
            });
            getVote(law.id).then(vote => {
                setStateDotMatrix(getDotMatrix(vote));
            });
        }
    }, [stateParty, stateLawIndex, stateLaws]);

    return (
        <div className={"h-full"}>
            <div className={"absolute top-0 left-0"}>
                <a href={process.env.URL}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-2xl text-center w-32 p-4"}>WAEHL-DICH.DE</h2>
                </a>
            </div>
            <div className={"absolute top-0 right-0"}>
                <a href={`${process.env.URL}/archive`}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-2xl text-center w-32 p-4"}>ARCHIV</h2>
                </a>
            </div>
            <div className={"absolute left-8 top-1/2 -translate-y-1/2 z-50"}>
                <button onClick={() => setStateLawIndex(stateLawIndex - 1)} className={stateVisibilityPreviousLaw}>
                    <ChevronLeftIcon aria-hidden="true" className="-mr-1 h-24 w-24 text-gray-400" />
                </button>
            </div>
            <div className={"absolute h-4/6 w-full top-1/6 flex flex-wrap items-center mx-auto 2xl:relative 2xl:flex 2xl:max-w-7xl"}>
                {
                    stateLaws.map((law, index) => {
                        if (stateLawIndex == index) {
                            return (
                                <div className={"mx-auto w-3/4 2xl:w-1/2"} key={`lawIndex${index}`}>
                                    <div className={"h-24 flex justify-end items-end"}>
                                        <h1 className={"text-2xl font-bold w-5/6 mb-1"}>{law.title}</h1>
                                        {getNavigationDots(stateLaws, stateLawIndex)}
                                    </div>
                                    <hr />
                                    <p className={"w-4/5 my-2 mx-auto"}>{law.text}</p>
                                </div>
                            );
                        }
                    })
                }
                <div className={"mx-auto w-3/4 2xl:w-1/2"}>
                    <div className={"w-full 2xl:w-5/6 2xl:float-right"}>
                        {stateDotMatrix}
                        {stateBarChart}
                    </div>
                </div>
            </div>
            <div className={"absolute right-8 top-1/2 -translate-y-1/2"}>
                <button onClick={() => setStateLawIndex(stateLawIndex + 1)} className={stateVisibilityNextLaw}>
                    <ChevronRightIcon aria-hidden="true" className="-mr-1 h-24 w-24 text-gray-400" />
                </button>
            </div>
            <div className={"fixed w-full bottom-10 flex justify-center items-center"}>
                <a href={`${process.env.URL}/impressum`}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-xl text-center w-40 p-4"}>Impressum</h2>
                </a>
                <a href={`${process.env.URL}/datenschutz`}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-xl text-center w-40 p-4"}>Datenschutz</h2>
                </a>
            </div>
        </div>
    );
}

async function getPartyVote(lawId: number): Promise<Array<IPartyVote> | undefined> {
    let partyVoteResponse: IPartyVoteResponse = await fetch(`${process.env.API_URL}/v1/party/vote/?id=${lawId}`).then(response => response.json());

    return partyVoteResponse.partyVoteData;
}

async function getParties(): Promise<Array<IParty>> {
    let partyResponse: IPartyResponse = await fetch(`${process.env.API_URL}/v1/party/`).then(response => response.json());

    return partyResponse.partyData;
}

async function getVote(lawId: number): Promise<IVote | undefined> {
    let voteResponse: IVoteResponse = await fetch(`${process.env.API_URL}/v1/vote/?id=${lawId}`).then(response => response.json());

    return voteResponse.voteData;
}

async function getLaws(): Promise<Array<ILaw>> {
    let lawResponse: ILawResponse = await fetch(`${process.env.API_URL}/v1/law/`).then(response => response.json());

    let lawData = lawResponse.lawData.map(law => {
        return {
            id: law.id,
            title: law.title,
            text: law.text,
            date: new Date(law.date)
        }
    });

    return lawData;
}

function getNavigationDots(laws: Array<ILaw>, lawIndex: number) {
    let classString = "w-1/6 min-w-28 h-10 mt-5 flex justify-center items-center";

    let lawCount = laws.length;

    if (lawCount <= 5) {
        return <div className={classString}>
            {laws.map((law, index) => {
                if (index == lawIndex) {
                    return getActiveDot(index);
                }
                return getDot(index);
            })}
        </div>
    } else {
        if (lawCount - lawIndex < 3) {
            return <div className={classString}>
                {laws.map((law, index) => {
                    if (index == lawIndex) {
                        return getActiveDot(index);
                    }
                    if (index == lawIndex + 1) {
                        return getfadeOutDot1(index);
                    }
                    if (index + lawIndex - (lawCount - 1) > lawIndex - 3) {
                        return getDot(index);
                    }
                    if (index + lawIndex - (lawCount - 1) > lawIndex - 4) {
                        return getfadeOutDot1(index);
                    }
                    if (index + lawIndex - (lawCount - 1) > lawIndex - 5) {
                        return getfadeOutDot2(index);
                    }
                })}
            </div>
        } else if (lawIndex < 2) {
            return <div className={classString}>
                {laws.map((law, index) => {
                    if (index == lawIndex) {
                        return getActiveDot(index);
                    }
                    if (index + 1 == lawIndex) {
                        return getfadeOutDot1(index);
                    }
                    if (index + lawIndex - 3 == lawIndex) {
                        return getfadeOutDot1(index);
                    }
                    if (index + lawIndex - 4 == lawIndex) {
                        return getfadeOutDot2(index);
                    }
                    if (index + lawIndex - 5 < lawIndex) {
                        return getDot(index);
                    }
                })}
            </div>
        } else {
            return <div className={classString}>
                {laws.map((law, index) => {
                    if (index == lawIndex) {
                        return getActiveDot(index);
                    }
                    if (index + 1 == lawIndex || index - 1 == lawIndex) {
                        return getfadeOutDot1(index);
                    }
                    if (index + 2 == lawIndex || index - 2 == lawIndex) {
                        return getfadeOutDot2(index);
                    }
                })}
            </div>
        }
    }
}

function getDot(dotKeyIndex: number) {
    return <div className={"h-3 w-3 m-1 mb-0 rounded-md bg-gray-600 dark:bg-white"} key={`navigationDot${dotKeyIndex}`}></div>
}

function getActiveDot(dotKeyIndex: number) {
    return <div className={"h-3 w-3 m-1 mb-0 rounded-md border-2 border-gray-600 dark:border-white"} key={`navigationDot${dotKeyIndex}`}></div>
}

function getfadeOutDot1(dotKeyIndex: number) {
    return <div className={"h-3 w-3 m-1 mb-0 rounded-md bg-slate-400 dark:bg-slate-300"} key={`navigationDot${dotKeyIndex}`}></div>
}

function getfadeOutDot2(dotKeyIndex: number) {
    return <div className={"h-3 w-3 m-1 mb-0 rounded-md bg-slate-300 dark:bg-slate-400"} key={`navigationDot${dotKeyIndex}`}></div>
}

function getDotMatrix(vote: IVote | undefined) {
    if (vote === undefined) {
        return <></>;
    }

    let positive = vote.votingResult.positive;
    let negative = vote.votingResult.negative;

    if (positive == 0 && negative == 0) {
        positive = 0.5;
        negative = 0.5;
    }

    let positivePercent = parseInt((positive * 10000).toString()) / 100;
    let negativePercent = parseInt((negative * 10000).toString()) / 100;

    let divContainer = <></>;
    let dotMatrix: Array<JSX.Element> = [];

    for (let index = 0; index < 100; index++) {
        let greenDot = <div className={"h-3 w-3 rounded-md bg-green-500"} key={`dot${index}`}></div>;
        let grayDot = <div className={"h-3 w-3 rounded-md bg-gray-500"} key={`dot${index}`}></div>
        let redDot = <div className={"h-3 w-3 rounded-md bg-red-500"} key={`dot${index}`}></div>

        if (positivePercent % 10 != 0 && index % 10 < (positivePercent / 10) - 1) {
            dotMatrix.push(greenDot);
        } else if (positivePercent % 10 == 0 && index % 10 < (positivePercent / 10)) {
            dotMatrix.push(greenDot);
        } else if (index % 10 < (positivePercent / 10) && index / 10 <= positivePercent % 10) {
            dotMatrix.push(greenDot);
        } else if (positivePercent + negativePercent !== 100 && index % 10 < ((positivePercent + 1) / 10) && index / 10 <= (positivePercent + 1) % 10) {
            dotMatrix.push(grayDot);
        } else {
            dotMatrix.push(redDot);
        }
    }

    divContainer = <div className={"w-full flex items-center justify-center"}>
        <h1 className={"text-3xl mr-3"}>{positivePercent} %</h1>
        <div className="grid grid-cols-10 grid-rows-10 gap-3 w-60 h-60 m-1.5">
            {dotMatrix}
        </div>
        <h1 className={"text-3xl ml-3"}>{negativePercent} %</h1>
    </div>

    return divContainer;
}

function getBarChart(partyVote: Array<IPartyVote> | undefined, parties: Array<IParty>) {
    if (partyVote === undefined) {
        return <></>;
    }

    let partyVoteGridParties = partyVote.filter(partyVote => {
        return parties.findIndex(party => partyVote.name == party.name && party.showLocation === "GRID") !== -1;
    });

    let barChartGridParties = partyVoteGridParties.map(partyVote => {
        let positivePercent = parseInt((partyVote.positive * 100).toString());
        let negativePercent = parseInt((partyVote.negative * 100).toString());
        let neutralPercent = parseInt((100 - (positivePercent + negativePercent)).toString());

        return <div className={"h-12 mb-2"} key={`party${partyVote.id}`}>
            <div className={"flex h-6 w-2/3 float-right"}>
                <div className={`flex items-center justify-center h-6 w-${positivePercent}%`}>
                    <p>{parseInt((partyVote.positive * 10000).toString()) / 100}%</p>
                </div>
                <div className={`flex items-center justify-center h-6 w-${neutralPercent}%`}>
                    <p>{parseInt((partyVote.neutral * 10000).toString()) / 100}%</p>
                </div>
                <div className={`flex items-center justify-center h-6 w-${negativePercent}%`}>
                    <p>{parseInt((partyVote.negative * 10000).toString()) / 100}%</p>
                </div>
            </div>
            <div className={"flex h-6 w-full"}>
                <p className={"h-6 w-1/3"}>{partyVote.name}</p>
                <div className={"flex justify-center items-center h-6 w-2/3"}>
                    <div className={`h-4 w-${positivePercent}% bg-green-500`}></div>
                    <div className={`h-4 w-${neutralPercent}% bg-gray-400 dark:bg-gray-600`}></div>
                    <div className={`h-4 w-${negativePercent}% bg-red-500`}></div>
                </div>
            </div>
        </div>
    });

    let partyVoteListParties = partyVote.filter(partyVote => {
        return parties.findIndex(party => partyVote.name == party.name && party.showLocation === "LIST") !== -1;
    });

    let barChartListParties = partyVoteListParties.map(partyVote => {
        let positivePercent = parseInt((partyVote.positive * 100).toString());
        let negativePercent = parseInt((partyVote.negative * 100).toString());
        let neutralPercent = parseInt((100 - (positivePercent + negativePercent)).toString());

        return <MenuItem key={`party${partyVote.id}`}>
            <div className={"h-12 mb-2"}>
                <div className={"flex h-6 w-2/3 float-right"}>
                    <div className={`flex items-center justify-center h-6 w-${positivePercent}%`}>
                        <p>{parseInt((partyVote.positive * 10000).toString()) / 100}%</p>
                    </div>
                    <div className={`flex items-center justify-center h-6 w-${neutralPercent}%`}>
                        <p>{parseInt((partyVote.neutral * 10000).toString()) / 100}%</p>
                    </div>
                    <div className={`flex items-center justify-center h-6 w-${negativePercent}%`}>
                        <p>{parseInt((partyVote.negative * 10000).toString()) / 100}%</p>
                    </div>
                </div>
                <div className={"flex h-6 w-full"}>
                    <p className={"h-6 w-1/3"}>{partyVote.name}</p>
                    <div className={"flex justify-center items-center h-6 w-2/3"}>
                        <div className={`h-4 w-${positivePercent}% bg-green-500`}></div>
                        <div className={`h-4 w-${neutralPercent}% bg-gray-400 dark:bg-gray-600`}></div>
                        <div className={`h-4 w-${negativePercent}% bg-red-500`}></div>
                    </div>
                </div>
            </div>
        </MenuItem>
    });

    let menuListParties = <></>;

    if (barChartListParties.length > 0) {
        menuListParties = <Menu as="div" className="pt-4 w-full relative inline-block text-left">
            <div>
                <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-400 dark:bg-gray-600 px-3 py-2 text-sm font-semibold shadow-sm hover:opacity-80">
                    Sonstige
                    <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5" />
                </MenuButton>
            </div>

            <MenuItems
                transition
                className="absolute p-2 right-0 z-10 mt-2 w-full origin-top-left rounded-md bg-gray-200 dark:bg-gray-500 shadow-lg transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in max-h-48 overflow-y-auto"
            >
                {barChartListParties}
            </MenuItems>
        </Menu>
    }

    return <div className={"mt-4"}>
        <hr className={"mb-2"} />
        {barChartGridParties}
        {menuListParties}
    </div>
}