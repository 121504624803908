import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Start } from "./pages/Start";
import { Vote } from "./pages/Vote";
import { Archive } from "./pages/Archive";

export function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Start />} />
                <Route path="/vote" element={<Vote />} />
                <Route path="/archive" element={<Archive />} />
            </Routes>
        </BrowserRouter>
    );
}