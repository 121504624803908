import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

interface ILawResponse {
    status: number
    message: string
    lawData: Array<ILaw>
}

interface ILaw {
    id: number
    title: string
    text: string
    date: Date
}

export function Vote() {
    const [stateLaws, setStateLaws] = useState<Array<ILaw>>([]);
    const [stateLawIndex, setStateLawIndex] = useState(0);
    const [stateVisibilityPreviousLaw, setStateVisibilityPreviousLaw] = useState<string>("hidden");
    const [stateVisibilityNextLaw, setStateVisibilityNextLaw] = useState<string>("hidden");

    let partyID = parseInt(useSearchParams()[0].get("partyID") || "");

    if (isNaN(partyID)) {
        partyID = -1;
    }

    getLaws().then(laws => {
        if (JSON.stringify(laws.sort()) !== JSON.stringify(stateLaws.sort())) {
            setStateLaws(laws)
        }
    });

    useEffect(() => {
        if (stateLawIndex > 0) {
            setStateVisibilityPreviousLaw("visible");
        } else {
            setStateVisibilityPreviousLaw("hidden");
        }

        if (stateLawIndex < stateLaws.length - 1) {
            setStateVisibilityNextLaw("visible");
        } else {
            setStateVisibilityNextLaw("hidden");
        }
    }, [stateLawIndex, stateLaws]);

    return (
        <div className={"h-full"}>
            <div className={"absolute top-0 left-0"}>
                <a href={process.env.URL}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-2xl text-center w-32 p-4"}>WAEHL-DICH.DE</h2>
                </a>
            </div>
            <div className={"absolute top-0 right-0"}>
                <a href={`${process.env.URL}/archive`}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-2xl text-center w-32 p-4"}>ARCHIV</h2>
                </a>
            </div>
            <div className={"absolute left-8 top-1/2 -translate-y-1/2 z-50"}>
                <button onClick={() => setStateLawIndex(stateLawIndex - 1)} className={stateVisibilityPreviousLaw}>
                    <ChevronLeftIcon aria-hidden="true" className="-mr-1 h-24 w-24 text-gray-400" />
                </button>
            </div>
            <div className={"h-5/6 flex items-center justify-center container mx-auto"}>
                {
                    stateLaws.map((law, index) => {
                        if (stateLawIndex == index) {
                            return (
                                <div className={"w-4/5 md:w-3/5"} key={`lawIndex${index}`}>
                                    <div className={"h-24 flex justify-end items-end"}>
                                        <h1 className={"text-2xl font-bold w-5/6 mb-1"}>{law.title}</h1>
                                        {getNavigationDots(stateLaws, stateLawIndex)}
                                    </div>
                                    <hr />
                                    <p className={"w-4/5 my-2 mx-auto"}>{law.text}</p>
                                    <div className={"flex justify-center py-2"}>
                                        <button
                                            className={"bg-gray-300 text-gray-950 w-28 px-4 py-2 mx-2 rounded-md hover:opacity-80"}
                                            onClick={() => clickVote(partyID, law.id, "POSITIVE", stateLaws, stateLawIndex, setStateLawIndex)}>
                                            DAFÜR!
                                        </button>
                                        <button
                                            className={"bg-gray-300 text-gray-950 w-28 px-4 py-2 mx-2 rounded-md hover:opacity-80"}
                                            onClick={() => clickVote(partyID, law.id, "NEGATIVE", stateLaws, stateLawIndex, setStateLawIndex)}>
                                            DAGEGEN!
                                        </button>
                                    </div>
                                </div>
                            );
                        }
                    })
                }
            </div>
            <div className={"absolute right-8 top-1/2 -translate-y-1/2"}>
                <button onClick={() => setStateLawIndex(stateLawIndex + 1)} className={stateVisibilityNextLaw}>
                    <ChevronRightIcon aria-hidden="true" className="-mr-1 h-24 w-24 text-gray-400" />
                </button>
            </div>
            <div className={"fixed w-full bottom-10 flex justify-center items-center"}>
                <a href={`${process.env.URL}/impressum`}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-xl text-center w-40 p-4"}>Impressum</h2>
                </a>
                <a href={`${process.env.URL}/datenschutz`}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-xl text-center w-40 p-4"}>Datenschutz</h2>
                </a>
            </div>
        </div>
    );
}

async function getLaws(): Promise<Array<ILaw>> {
    let lawResponse: ILawResponse = await fetch(`${process.env.API_URL}/v1/law/`).then(response => response.json());

    let lawData = lawResponse.lawData.map(law => {
        return {
            id: law.id,
            title: law.title,
            text: law.text,
            date: new Date(law.date)
        }
    });

    return lawData;
}

async function clickVote(partyID: number, lawID: number, vote: string, laws: Array<ILaw>, lawIndex: number, setStateLawIndex: Function) {
    const requestOptions: RequestInit = {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            partyId: partyID,
            lawId: lawID,
            votingResult: vote
        })
    }

    await fetch(`${process.env.API_URL}/v1/vote/`, requestOptions);

    if (lawIndex < laws.length - 1) {
        setStateLawIndex(lawIndex + 1);
    } else {
        document.location = "/archive";
    }
}

function getNavigationDots(laws: Array<ILaw>, lawIndex: number) {
    let classString = "w-1/6 min-w-28 h-10 mt-5 flex justify-center items-center";

    let lawCount = laws.length;

    if (lawCount <= 5) {
        return <div className={classString}>
            {laws.map((law, index) => {
                if (index == lawIndex) {
                    return getActiveDot(index);
                }
                return getDot(index);
            })}
        </div>
    } else {
        if (lawCount - lawIndex < 3) {
            return <div className={classString}>
                {laws.map((law, index) => {
                    if (index == lawIndex) {
                        return getActiveDot(index);
                    }
                    if (index == lawIndex + 1) {
                        return getfadeOutDot1(index);
                    }
                    if (index + lawIndex - (lawCount - 1) > lawIndex - 3) {
                        return getDot(index);
                    }
                    if (index + lawIndex - (lawCount - 1) > lawIndex - 4) {
                        return getfadeOutDot1(index);
                    }
                    if (index + lawIndex - (lawCount - 1) > lawIndex - 5) {
                        return getfadeOutDot2(index);
                    }
                })}
            </div>
        } else if (lawIndex < 2) {
            return <div className={classString}>
                {laws.map((law, index) => {
                    if (index == lawIndex) {
                        return getActiveDot(index);
                    }
                    if (index + 1 == lawIndex) {
                        return getfadeOutDot1(index);
                    }
                    if (index + lawIndex - 3 == lawIndex) {
                        return getfadeOutDot1(index);
                    }
                    if (index + lawIndex - 4 == lawIndex) {
                        return getfadeOutDot2(index);
                    }
                    if (index + lawIndex - 5 < lawIndex) {
                        return getDot(index);
                    }
                })}
            </div>
        } else {
            return <div className={classString}>
                {laws.map((law, index) => {
                    if (index == lawIndex) {
                        return getActiveDot(index);
                    }
                    if (index + 1 == lawIndex || index - 1 == lawIndex) {
                        return getfadeOutDot1(index);
                    }
                    if (index + 2 == lawIndex || index - 2 == lawIndex) {
                        return getfadeOutDot2(index);
                    }
                })}
            </div>
        }
    }
}

function getDot(dotKeyIndex: number) {
    return <div className={"h-3 w-3 m-1 mb-0 rounded-md bg-gray-600 dark:bg-white"} key={`navigationDot${dotKeyIndex}`}></div>
}

function getActiveDot(dotKeyIndex: number) {
    return <div className={"h-3 w-3 m-1 mb-0 rounded-md border-2 border-gray-600 dark:border-white"} key={`navigationDot${dotKeyIndex}`}></div>
}

function getfadeOutDot1(dotKeyIndex: number) {
    return <div className={"h-3 w-3 m-1 mb-0 rounded-md bg-slate-400 dark:bg-slate-300"} key={`navigationDot${dotKeyIndex}`}></div>
}

function getfadeOutDot2(dotKeyIndex: number) {
    return <div className={"h-3 w-3 m-1 mb-0 rounded-md bg-slate-300 dark:bg-slate-400"} key={`navigationDot${dotKeyIndex}`}></div>
}