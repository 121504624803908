import React, { useState } from "react";
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

interface IPartyResponse {
    status: number
    message: string
    partyData: Array<IParty>
}

interface IParty {
    id: number
    name: string
    showLocation: string
}

export function Start() {
    const [statePartiesGrid, setStatePartiesGrid] = useState<Array<IParty>>([]);
    const [statePartiesList, setStatePartiesList] = useState<Array<IParty>>([]);

    getParties().then(parties => {
        let partiesGrid = parties.filter(party => party.showLocation == "GRID")
        if (JSON.stringify(partiesGrid.sort()) !== JSON.stringify(statePartiesGrid.sort())) {
            setStatePartiesGrid(partiesGrid)
        }

        let partiesList = parties.filter(party => party.showLocation == "LIST")
        if (JSON.stringify(partiesList.sort()) !== JSON.stringify(statePartiesList.sort())) {
            setStatePartiesList(partiesList)
        }
    });

    return (
        <div className={"h-full"}>
            <div className={"absolute top-0 left-0"}>
                <a href={process.env.URL}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-2xl text-center w-32 p-4"}>WAEHL-DICH.DE</h2>
                </a>
            </div>
            <div className={"absolute top-0 right-0"}>
                <a href={`${process.env.URL}/archive`}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-2xl text-center w-32 p-4"}>ARCHIV</h2>
                </a>
            </div>
            <div className={"h-5/6 flex items-center justify-center container mx-auto"}>
                <div>
                    <h1 className={"text-3xl text-center"}>Wen würdest du wählen,<br /> wenn morgen Bundestagswahl wäre?</h1>
                    <div className={"pt-8 grid grid-cols-3 gap-4"}>
                        {statePartiesGrid.map(party => {
                            return (<a href={`${process.env.URL}/vote?partyID=${party.id}`} key={`party${party.id}`}>
                                <div className={"rounded-md size-48 flex items-center justify-center bg-gray-400 dark:bg-gray-600 hover:opacity-80"}>
                                    <p className={"text-2xl"}>{party.name}</p>
                                </div>
                            </a>);
                        })}
                    </div>
                    <Menu as="div" className="pt-4 w-full relative inline-block text-left">
                        <div>
                            <MenuButton className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-gray-400 dark:bg-gray-600 px-3 py-2 text-sm font-semibold shadow-sm hover:opacity-80">
                                Sonstige
                                <ChevronDownIcon aria-hidden="true" className="-mr-1 h-5 w-5" />
                            </MenuButton>
                        </div>

                        <MenuItems
                            transition
                            className="absolute p-2 right-0 z-10 mt-2 w-full origin-top-left rounded-md bg-gray-300 dark:bg-slate-400 shadow-lg transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in grid grid-cols-2 gap-2 max-h-48 overflow-scroll"
                        >
                            {statePartiesList.map(party => {
                                return (<MenuItem key={`party${party.id}`}>
                                    <a href={`${process.env.URL}/vote?partyID=${party.id}`}>
                                        <div className={"rounded-md w-full flex items-center justify-center bg-gray-400 dark:bg-gray-600  hover:opacity-80"}>
                                            <p className={"text-1xl"}>{party.name}</p>
                                        </div>
                                    </a>
                                </MenuItem>);
                            })}
                        </MenuItems>
                    </Menu>
                    <hr className={"border-t-2 w-3/5 mx-auto my-4"} />
                    <a href={`${process.env.URL}/vote?partyID=UNKNOWN`} className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                        <h1 className={"text-2xl text-center"}>KANN EUCH DOCH EGAL SEIN!</h1>
                    </a>
                </div>
            </div>
            <div className={"fixed w-full bottom-10 flex justify-center items-center"}>
                <a href={`${process.env.URL}/impressum`}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-xl text-center w-40 p-4"}>Impressum</h2>
                </a>
                <a href={`${process.env.URL}/datenschutz`}
                    className={"cursor-pointer hover:opacity-80 transition duration-200 ease-in-out"}>
                    <h2 className={"text-xl text-center w-40 p-4"}>Datenschutz</h2>
                </a>
            </div>
        </div>
    );
}

async function getParties(): Promise<Array<IParty>> {
    let partyResponse: IPartyResponse = await fetch(`${process.env.API_URL}/v1/party/`).then(response => response.json());

    return partyResponse.partyData;
}